import { ImageBackdrop } from "src/components/ImageBackdrop/ImageBackdrop"
import { OnboardingCard } from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/organizationCreateWizardStyles"
import {
  ORG_NAME_FORM_ID,
  OrganizationNameStep,
} from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/OrganizationNameStep/OrganizationNameStep"
import { useOrganizationNameForm } from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/OrganizationNameStep/useOrganizationNameForm"
import { useAppData } from "src/context/useAppData"
import {
  usePatchOrganization,
  usePostOrganization,
} from "src/data/organizations/queries/organizationQueries"
import { IOrganizationResponse } from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { backgroundGrayV3 } from "src/ui/colors"
import backdropImage from "src/ui/images/photo-orange-pink-tower.jpg"
import backdropImagePortrait from "src/ui/images/photo-orange-pink-tower-portrait.jpg"
import { InlineWizard } from "src/ui/Wizard/InlineWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"

/**
 * Create organization flow with less steps for users that own at least one organization
 */
export function OrganizationCreateWizard() {
  const { t, langKeys } = useTranslate()
  const { navigate, goBack } = useRouter()

  const { setActiveOrgId } = useAppData()

  const postOrganization = usePostOrganization()
  const patchOrganization = usePatchOrganization()

  const nameForm = useOrganizationNameForm({
    initialValues: {
      name: "",
    },
    onSubmit: () => {
      handleCreateOrganization()
    },
  })

  function handleCreateOrganization() {
    return postOrganization.mutate(
      { name: nameForm.values["name"] },
      { onSuccess: handleCreateOrganizationSuccess }
    )
  }

  function handleCreateOrganizationSuccess(orgResponse: IOrganizationResponse) {
    const newOrgId = orgResponse.id
    setActiveOrgId(newOrgId)
    navigate(Routes.Dashboard.location(), { orgIdOverride: newOrgId })
  }

  const steps: IWizardStep[] = [
    {
      component: <OrganizationNameStep formData={nameForm} />,
      nextButtonLabel: t(langKeys.organization_create),
      onBack: () => {
        goBack({ defaultPath: Routes.Dashboard.location() })
      },
      nextButtonProps: {
        form: ORG_NAME_FORM_ID,
        loading: patchOrganization.isLoading || postOrganization.isLoading,
      },
      backButtonLabel: t(langKeys.close),
    },
  ]

  return (
    <ImageBackdrop
      landscapeImageUrl={backdropImage}
      portraitImageUrl={backdropImagePortrait}
      backgroundColor={backgroundGrayV3}
    >
      <OnboardingCard>
        <InlineWizard currentStep={0} steps={steps} showProgress={false} />
      </OnboardingCard>
    </ImageBackdrop>
  )
}
